const components = [
    {
      "label": "Description",
      "placeholder": "Description",
      "tooltip": "Task details",
      "editor": "ckeditor",
      "autoExpand": false,
      "tabindex": "2",
      "tableView": false,
      "clearOnHide": false,
      "key": "description",
      "type": "textarea",
      "labelWidth": 5,
      "labelMargin": 1,
      "rows": 5,
      "wysiwyg": {
          "toolbarGroups": [{
              "groups": ["simplestyles", "cleanup"]
          }, {
              "config": ["toolbarCanCollapse:true", "toolbarStartupExpanded:false"]
          }, {
              "name": "paragraph",
              "groups": ["list", "indent", "blocks", "align", "bidi", "paragraph", "-", "JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"]
          }, {
              "name": "links",
              "groups": ["links"]
          }, {
              "name": "insert",
              "groups": ["insert"]
          }, "/", {
              "name": "styles",
              "groups": ["Styles", "Format", "Font", "FontSize"]
          }, {
              "name": "colors",
              "groups": ["colors"]
          }, {
              "name": "clipboard",
              "groups": ["clipboard", "undo"]
          }, {
              "name": "editing",
              "groups": ["find", "selection", "spellchecker", "editing"]
          }, {
              "name": "document",
              "groups": ["mode", "document", "doctools"]
          }, {
              "name": "others",
              "groups": ["others"]
          }, {
              "name": "tools",
              "groups": ["tools"]
          }],
          "removeButtons": "Cut,Copy,Paste,Underline,Subscript,Superscript,Scayt,About",
          "uiColor": "red",
          "height": "400px",
          "width": "100%",
          "rows": 5,
          "base64Upload": true,
          "mediaEmbed": {
              "previewsInData": true
          },
          "image": {
              "toolbar": ["imageTextAlternative", "|", "imageStyle:full", "imageStyle:alignLeft", "imageStyle:alignCenter", "imageStyle:alignRight"],
              "styles": ["full", "alignLeft", "alignCenter", "alignRight"]
          }
      },
      "input": true,
      "hideOnChildrenHidden": false,
      "width": 12,
      "isUploadEnabled": false,
      "prefix": "",
      "customClass": "",
      "suffix": "",
      "multiple": false,
      "defaultValue": null,
      "protected": false,
      "unique": false,
      "persistent": true,
      "hidden": false,
      "refreshOn": "",
      "redrawOn": "",
      "modalEdit": false,
      "dataGridLabel": false,
      "labelPosition": "top",
      "description": "",
      "errorLabel": "",
      "hideLabel": false,
      "disabled": false,
      "autofocus": false,
      "dbIndex": false,
      "customDefaultValue": "",
      "calculateValue": "",
      "calculateServer": false,
      "widget": {
          "type": "input"
      },
      "attributes": {},
      "validateOn": "change",
      "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
      },
      "conditional": {
          "show": null,
          "when": null,
          "eq": ""
      },
      "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
      },
      "allowCalculateOverride": false,
      "encrypted": false,
      "showCharCount": false,
      "showWordCount": false,
      "properties": {},
      "allowMultipleMasks": false,
      "mask": false,
      "inputType": "text",
      "inputFormat": "html",
      "inputMask": "",
      "spellcheck": true,
      "fixedSize": true,
      "id": "e6jv6do"
  }
  ];
  export default { components };
  