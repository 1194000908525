//this file will export all the helper functions/classes
//utils file has all common/most used functions
import Utils from "./utils";
import ParameterHandler from "./ParameterHandler";
import PopupContext from "./PopupContext";
import Tabs from "./custom-components/tabs";
export default { Utils, ParameterHandler };
//export any re-usable react component separately

export { PopupContext, Tabs };
