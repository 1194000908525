const components = [
    {
      "label": "Name",
      "placeholder": "Enter the name of the task",
      "tabindex": "1",
      "autofocus": true,
      "tableView": false,
      "validate": {
          "required": true,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": ""
      },
      "key": "name",
      "type": "textfield",
      "labelWidth": 7,
      "labelMargin": 1,
      "input": true,
      "lockKey": true,
      "hideOnChildrenHidden": false,
      "prefix": "",
      "customClass": "",
      "suffix": "",
      "multiple": false,
      "defaultValue": null,
      "protected": false,
      "unique": false,
      "persistent": true,
      "hidden": false,
      "clearOnHide": true,
      "refreshOn": "",
      "redrawOn": "",
      "modalEdit": false,
      "dataGridLabel": false,
      "labelPosition": "top",
      "description": "",
      "errorLabel": "",
      "tooltip": "",
      "hideLabel": false,
      "disabled": false,
      "dbIndex": false,
      "customDefaultValue": "",
      "calculateValue": "",
      "calculateServer": false,
      "widget": {
          "type": "input"
      },
      "attributes": {},
      "validateOn": "change",
      "conditional": {
          "show": null,
          "when": null,
          "eq": ""
      },
      "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
      },
      "allowCalculateOverride": false,
      "encrypted": false,
      "showCharCount": false,
      "showWordCount": false,
      "properties": {},
      "allowMultipleMasks": false,
      "mask": false,
      "inputType": "text",
      "inputFormat": "plain",
      "inputMask": "",
      "spellcheck": true,
      "id": "e14kdp9"
  }
  ];
  export default { components };
  