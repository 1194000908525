const components = [
	{
		"label": "City",
		"tabindex": "9",
		"autocomplete": "off",
		"tableView": true,
		"validate": {
		  "required": true,
		  "custom": "if(data['city'] && data['city'] != '')\n{\n  valid = (data['city'].match(/^[a-zA-z]+([\\s])*([\\s][a-zA-Z]+)*([\\s])*$/)) ?  true : 'Please enter a valid city name.';\n}\n",
		  "customPrivate": false,
		  "strictDateValidation": false,
		  "multiple": false,
		  "unique": false,
		  "minLength": "",
		  "maxLength": "",
		  "pattern": ""
		},
		"key": "city",
		"type": "textfield",
		"input": true,
		"lockKey": true,
		"hideOnChildrenHidden": false,
		"placeholder": "",
		"prefix": "",
		"customClass": "",
		"suffix": "",
		"multiple": false,
		"defaultValue": null,
		"protected": false,
		"unique": false,
		"persistent": true,
		"hidden": false,
		"clearOnHide": true,
		"refreshOn": "",
		"redrawOn": "",
		"modalEdit": false,
		"dataGridLabel": false,
		"labelPosition": "top",
		"description": "",
		"errorLabel": "",
		"tooltip": "",
		"hideLabel": false,
		"disabled": false,
		"autofocus": false,
		"dbIndex": false,
		"customDefaultValue": "",
		"calculateValue": "",
		"calculateServer": false,
		"widget": {
		  "type": "input"
		},
		"attributes": {},
		"validateOn": "change",
		"conditional": {
		  "show": null,
		  "when": null,
		  "eq": ""
		},
		"overlay": {
		  "style": "",
		  "left": "",
		  "top": "",
		  "width": "",
		  "height": ""
		},
		"allowCalculateOverride": false,
		"encrypted": false,
		"showCharCount": false,
		"showWordCount": false,
		"properties": {},
		"allowMultipleMasks": false,
		"mask": false,
		"inputType": "text",
		"inputFormat": "plain",
		"inputMask": "",
		"spellcheck": true,
		"id": "eobpsmi"
	  }
];
export default { components };
