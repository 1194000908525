import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import CommonHeader from "../../helpers/custom-react-components/common-header";
import PrintPdf from "./../print/printpdf";
import ActivityLog from "./ActivityLog";
import HeaderComponent from "./EntityViewerHeader";
import TabSegment from "./TabSegment";
class EntityViewer extends React.Component {
  constructor(props) {
    super(props);
    this.core = this.props.core;
    this.helper = this.core.make("oxzion/restClient");
    this.profileAdapter = this.core.make("oxzion/profile");
    this.messageBox = this.core.make("oxzion/messageDialog");
    this.profile = this.profileAdapter.get();
    this.appId = this.props.appId;
    this.fileId = this.props.fileId;
    this.proc = this.props.proc;
    this.childrenComponents = this.props.childrenComponents;
    this.unmounted = false;
    this.edit=this.props.edit;
    this.state = {
      content: this.props.content,
      fileData: this.props.fileData,
      entityId: null,
      showAuditLog: false,
      showPDF: false,
      dataReady: false,
      editButton: null,
      entityConfig: null,
      filePanelUuid: this.uuidv4(),
      tabPanel: "tabpanel-",
      isTabSegment: false,
      allowHeader: false,
    };
  }

  componentDidMount() {
    const allowHeader = this.setAppUpgrade();
    this.getFileDetails(this.props.fileId).then((fileData) => {
      if (
        fileData.status == "success" &&
        fileData.data &&
        fileData.data.entity_id
      ) {
        this.getEntityPage(fileData.data.entity_id).then((entityPage) => {
          var file={...fileData?.data?.data,...fileData.data};
          this.generateEditButton(entityPage.data, file);
          this.setState({
            entityId: file.entity_id,
            fileData: file,
            entityConfig: entityPage.data,
            content: this.constructTabs(entityPage.data, file),
            dataReady: true,
          });
        });
      }else{
        this.messageBox.show(fileData.errors[0]['message'], '', 'OK', false);
      }
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  async getFileDetails(fileId) {
    return this.helper.getMemoizedData(
      this.appId,
      "FILE",
      "/app/" + this.appId + "/file/" + fileId + "/data"
    );
  }

  async getEntityPage(entityId) {
    let fileContent = await this.helper.request(
      "v1",
      "/app/" + this.appId + "/entity/" + entityId + "/page",
      {},
      "get"
    );
    return fileContent;
  }

  updatePageContent = (config) => {
    let eventDiv = document.getElementById("navigation_" + this.appId);
    let ev2 = new CustomEvent("addPage", {
      detail: config,
      bubbles: true,
    });
    eventDiv.dispatchEvent(ev2);
  };

  generateEditButton(entityConfig, fileData) {
    var fileId;
    let i = 0;
    const toolbarButtons = [];
    if (this.props.fileId) {
      fileId = this.props.fileId;
    } else {
      if (this.state.fileId) {
        fileId = this.state.fileId;
      }
    }
    if (entityConfig && !entityConfig.has_workflow) {
      let formPage = {
        pageContent: [
          {
            type: "Form",
            form_id: entityConfig.form_uuid,
            name: entityConfig.form_name,
            fileId: fileId,
          },
        ],
        title: "Edit",
        icon: "far fa-pencil",
      };
    if(this.edit==1)
    {
      this.updatePageContent(formPage);
    }
    else{
      toolbarButtons.push(
        <Button
          key={i++}
          title={"Edit"}
          className={"btn btn-primary"}
          primary={true}
          onClick={(e) => this.updatePageContent(formPage)}
        >
          <i className={"fa fa-pencil"} />
        </Button>
      );
  }
    if (entityConfig?.enablePrint && !this?.state?.isTabSegment) {
      toolbarButtons.push(
        <Button
          key={i++}
          title={"Print"}
          className={"btn btn-primary"}
          primary={true}
          onClick={() => this.setState({ showPDF: true })}
        >
          <i className={"fa fa-print"} />
        </Button>
      );
    }
    if (entityConfig?.enableAuditLog && !this?.state?.isTabSegment) {
      toolbarButtons.push(
        <Button
          key={i++}
          title={"Audit Log"}
          className={"btn btn-primary"}
          primary={true}
          onClick={() => this.setState({ showAuditLog: true })}
        >
          <i className={"fa fa-history"} />
        </Button>
      );
    }
    // if (entityConfig.enableComments != "0" && !this.state.isTabSegment) {
    //   var commentPage = {
    //     title: "Comments",
    //     icon: "far fa-comment",
    //     pageContent: [{
    //       type: "RenderButtons",
    //       content: {
    //         buttonList: [
    //           {
    //             name: "Back",
    //             icon: "fa fa-arrow-left",
    //             type: "stepDownPage"
    //           },
    //         ],
    //     }},{ type: "Comment", fileId: fileId }],
    //   };
    //   toolbarButtons.push(
    //     <Button
    //       key={i++}
    //       title={"Comments"}
    //       className={"btn btn-primary"}
    //       primary={true}
    //       onClick={(e) => this.updatePageContent(commentPage)}
    //     >
    //       <i className={"fa fa-comment"} />
    //     </Button>
    //   );
    // }
    toolbarButtons.find((item) => item.title == "Generate Link")
      ? null
      : toolbarButtons.push(
          <Button
            title={"Share"}
            key={i++}
            className={"btn btn-primary"}
            primary={true}
            onClick={(e) => {
              this.props.notif.current.notify(
                "",
                "Link has been copied to clipboard",
                "success",
                undefined,
                2000
              );
              this.core
                .make("oxzion/link")
                .copyToClipboard(
                  this.core.config("ui.url") +
                    "?app=" +
                    entityConfig.app_name +
                    "&fileId=" +
                    fileId
                );
            }}
          >
            <i className={"fa fa-share-alt"} />
          </Button>
        );

    let gridToolbarContent = [
      <div style={{zIndex:10}}
        className={`display-flex ${
          this.state.isTabSegment ? "task-header-pos-abs" : ""
        }`}
      >
        {toolbarButtons}
      </div>,
    ];

    let ev = new CustomEvent("addcustomActions", {
      detail: { customActions: gridToolbarContent, pageId: this.props.pageId },
      bubbles: true,
    });
    document.getElementById(this.appId + "_breadcrumbParent").dispatchEvent(ev);
  }
  }

  setAppUpgrade() {
    try {
      let { app_properties } = this.props.appData;
      const allowHeader = !!JSON.parse(app_properties).app_upgrade;
      this.setState({ allowHeader });
      return allowHeader;
    } catch (e) {
      return false;
    }
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  constructTabs(page, file) {
    var tabs = [];
    var that = this;
    var content = page?.content ? page.content : null;
    var finalContentArray = [];
    if (content && content.length > 0) {
      content.map(function (key, index) {
        content[index]["fileId"] = that.fileId;
        finalContentArray.push(content[index]);
      });
    }
    if (finalContentArray && page?.enable_view) {
      tabs.push({
        name: "View",
        uuid: that.state.filePanelUuid,
        content: finalContentArray,
      });
    }
    if (page?.enable_documents != "0") {
      tabs.push({
        name: "Attachments",
        uuid: generateUUID(),
        content: [
          { type: "DocumentViewer", url: "file/" + this.fileId + "/document" },
        ],
      });
    }
    if (page?.enable_comments != "0") {
      tabs.push({
        name: "Activity",
        uuid: this.uuidv4(),
        content: [
          {
            ...this.props,
            type: "Comment",
            appId: this.appId,
            fileId: this.fileId,
            core: this.core,
            disableControls: true,
          },
        ],
      });
    }
    if (page?.enable_auditlog != "0") {
      tabs.push({
        name: "Audit Logs",
        uuid: this.uuidv4(),
        content: [
          {
            ...this.props,
            type: "History",
            appId: this.appId,
            fileId: this.fileId,
            core: this.core,
            disableControls: true,
          },
        ],
      });
    }
    if (page?.enable_relation != "0") {
      let that = this;
      page?.relations_config?.map(function (key, index) {
        let columnConfiguration = [];
        page.relations_config[index].selectColumns.map(function (columns, colIndex) {
          columnConfiguration.push({
            title:columns.text,
            field:columns.name,
            filter :columns.data_type,
          })
        })
        tabs.push({
          name: page.relations_config[index].relationTabName,
          content: [{
            gridContent: {
              "relation_name":page.relations_config[index].relationTabName,
              "route": `file/${that.fileId}/filerelation/${page.relations_config[index].relationsRoute}`,
              "relationsParentRoute":`${page.relations_config[index].addRelationRoute}`,
              "parent_fileId":`${that.fileId}`,
              "disableAppId": false,
              "columnConfig": columnConfiguration,
              "actions": [
                {
                  "name": "View",
                  "icon": "fa fa-eye",
                  "rule": "true",
                  "defaultAction": false,
                  "details": [
                    {
                      "type": "EntityViewer"
                    }
                  ],
                  "Name": "View"
                },
              ],
            //   "operations": {
            //     "actions": [
            //         {
            //             "details": [
            //                 {
            //                     "type": "Form",
            //                     "formSource": "existingForm",
            //                     "form_id": "66a85750-ac48-48b0-88af-11ed8eeb36b3"
            //                 }
            //             ],
            //             "name": "Create",
            //             "icon": "fas fa-plus",
            //             "rule": "true"
            //         }
            //     ],
            //     "title": "Operation"
            // }
            },
            type: "List",
            appId: that.appId,
            fileId: that.fileId,
            core: that.core,
          }],
          uuid: that.uuidv4(),
          isRelationTab: true
        });
      });
    }

    return (
      <div>
        {this.childrenComponents &&
        this.childrenComponents != undefined &&
        !(
          this.childrenComponents.length > 0 &&
          this.childrenComponents.name.includes("header")
        ) ? (
          <></>
        ) : (
          <div className="entityViewerHeader" style={{ marginTop: "-40px",position: "relative",zIndex: "2"}} >
          <HeaderComponent
            appId={this.appId}
            fileId={this.props.fileId}
            core={this.core}
            fileData={file}
            pageId={this.uuidv4()}
            proc={this.proc}
          />
             </div>
        )}
        <TabSegment
          appId={this.appId}
          core={this.core}
          proc={this.proc}
          fileId={this.state.fileId}
          tabs={tabs}
          pageId={this.uuidv4()}
          currentRow={file}
          tab={this.tab}
        />
    </div>
    );
  }

  render() {
    if (this.state.dataReady) {
      return (
        <div className="contentDiv">
          {this.state.showPDF ? (
            <PrintPdf
              cancel={() => this.setState({ showPDF: false })}
              idSelector={this.state.tabPanel + "" + this.state.filePanelUuid}
              osjsCore={this.core}
            />
          ) : null}
          {this.state.allowHeader && (
            <CommonHeader
              appId={this.appId}
              core={this.core}
              fileData={this.state.fileData}
            />
          )}
          {this.state.content}
          {this.state.showAuditLog ? (
            <ActivityLog
              cancel={() => this.setState({ showAuditLog: false })}
              appId={this.appId}
              fileId={this.fileId}
              core={this.core}
            />
          ) : null}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default EntityViewer;

var generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
