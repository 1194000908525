import React, { Component, lazy, Suspense, useState} from "react";
import OrgChart from "@balkangraph/orgchart.js";
import ReactDOM from "react-dom";

import * as Moment from "moment";
import * as MomentTZ from "moment-timezone";
import * as PopupDialog from "sweetalert2";
import * as ReactStrap from "reactstrap";
import * as Antd from "antd";
import * as AntdIcons from "@ant-design/icons";
import * as ReactBootstrap from "react-bootstrap";
import * as KendoFileUploader from "@progress/kendo-react-upload";
import * as KendoReactButtons from "@progress/kendo-react-buttons";
import * as KendoReactDropDowns from "@progress/kendo-react-dropdowns";
import * as KendoReactDateInputs from "@progress/kendo-react-dateinputs";
import * as KendoReactPopup from "@progress/kendo-react-popup";
import * as KendoReactLayout from "@progress/kendo-react-layout";
import * as KendoDataQuery from "@progress/kendo-data-query";
import * as KendoReactWindow from "@progress/kendo-react-dialogs";
import * as Window from "@progress/kendo-react-dialogs";
import * as KendoReactGrid from "@progress/kendo-react-grid";
import * as KendoReactInput from "@progress/kendo-react-inputs";
import * as Input from "@progress/kendo-react-inputs";
import * as Ripple from "@progress/kendo-react-ripple";
import * as orderBy from "@progress/kendo-data-query";

import EOXApplication from "./src/EOXApplication";
import LeftMenuTemplate from "./src/LeftMenuTemplate";
import Notification from "./src/Notification";
import DateComponent from "./src/components/DateComponent.js";
import countryStateList from "./src/components/data/country-state-codes";
import OX_Grid from "./src/components/OI/OX_Grid";
import DashboardManager from "./src/components/OI/DashboardManager";
import Dashboard from "./src/components/OI/Dashboard";
import DataSource from "./src/components/OI/DataSource";
import Query from "./src/components/OI/Query";
import PageContent from "./src/components/App/PageContent";
import DashboardFilter from "./src/components/OI/DashboardFilter";
import WidgetGrid from "./src/components/OI/WidgetGrid";
import WidgetRenderer from "./src/components/OI/WidgetRenderer";
import DocumentList from "./src/DocumentList";
import Visualization from "./src/components/OI/Visualization";
import WidgetManager from "./src/components/OI/WidgetManager";
import TemplateManager from "./src/components/OI/TemplateManager";
import SSOCustom from "./src/components/Custom/SSOCustom";
import EOXGrid from "./src/components/EOXGrid/EOXGrid";
import ChildEOXGrid from "./src/components/EOXGrid/ChildEOXGrid";
import Helpers, { PopupContext, Tabs } from "./src/helpers";
import * as ModalPopup from "./src/components/modal-popups";
import * as FormIoReact from "./src/components/formio-react";
import JavascriptLoader from "./src/components/javascriptLoader";
import { ckeditorConfig } from "./src/components/OI/CkEditorConfig";
import Utils from "./src/helpers/utils";

import "./index.scss";


import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
library.add(fal);

const LazyLoad = ({ component: Component, ...rest }) => (
  <React.Suspense
    fallback={
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    }>
    <Component {...rest} />
  </React.Suspense>
);

const FormRender = (props) => <LazyLoad component={lazy(() => import("./src/components/App/FormRender"))} {...props} />;
const FormBuilder = (props) => <LazyLoad component={lazy(() => import("./src/components/App/FormBuilder"))} {...props} />;
const MultiSelect = (props) => <LazyLoad component={lazy(() => import("./src/MultiSelect"))} {...props} />;
const HTMLViewer = (props) => <LazyLoad component={lazy(() => import("./src/components/App/HTMLViewer"))} {...props} />;
const CommentsView = (props) => <LazyLoad component={lazy(() => import("./src/components/App/CommentsView"))} {...props} />;
const DocumentViewer = (props) => <LazyLoad component={lazy(() => import("./src/DocumentViewer"))} {...props} />;
const DateFormats = (props) => <LazyLoad component={lazy(() => import("./src/public/js/DateFormats.js"))} {...props} />;
const DropDown = (props) => <LazyLoad component={lazy(() => import("./src/components/Dropdown/DropDownList"))} {...props} />;


export {
  EOXApplication,
  LeftMenuTemplate,
  Notification,
  MultiSelect,
  HTMLViewer,
  CommentsView,
  FormRender,
  OX_Grid,
  ChildEOXGrid,
  DocumentViewer,
  DashboardManager,
  Dashboard,
  WidgetGrid,
  WidgetRenderer,
  DataSource,
  Query,
  DashboardFilter,
  React,
  useState,
  PageContent,
  ReactDOM,
  DateFormats,
  ReactStrap,
  ReactBootstrap,
  KendoFileUploader,
  KendoReactDateInputs,
  KendoReactPopup,
  KendoReactDropDowns,
  KendoReactLayout,
  KendoDataQuery,
  KendoReactButtons,
  KendoReactWindow,
  KendoReactGrid,
  KendoReactInput,
  Window,
  orderBy,
  Input,
  Ripple,
  PopupDialog,
  Moment,
  MomentTZ,
  DateComponent,
  DropDown,
  countryStateList,
  FormBuilder,
  Suspense,
  Visualization,
  WidgetManager,
  DocumentList,
  Antd,
  AntdIcons,
  TemplateManager,
  SSOCustom,
  EOXGrid,
  Helpers,
  ModalPopup,
  FormIoReact,
  PopupContext,
  Tabs,
  JavascriptLoader,
  ckeditorConfig,
  Utils,
  Component,
  OrgChart
};
