const components = [
    {
      label: "Start Date",
      labelPosition: "top",
      displayInTimezone: "viewer",
      useLocaleSettings: false,
      allowInput: true,
      format: "yyyy-MM-dd",
      placeholder: "Start Date",
      description: "",
      tooltip: "Please select the start date of the task.",
      customClass: "",
      tabindex: "4",
      hidden: false,
      hideLabel: false,
      autofocus: false,
      disabled: false,
      tableView: false,
      modalEdit: false,
      shortcutButtons: [],
      enableDate: true,
      enableMinDateInput: true,
      datePicker: {
        minDate: "moment().calendar(); ",
        maxDate: "moment().add(data.end_date_protected).calendar();  ",
        disable: "",
        disableFunction: '{\n    disable: [data.next_action_date_protected,data.end_date_protected ],\n    dateFormat: "Y-m-d",\n}',
        disableWeekends: false,
        disableWeekdays: false,
        showWeeks: true,
        startingDay: 0,
        initDate: "",
        minMode: "day",
        maxMode: "year",
        yearRows: 4,
        yearColumns: 5,
      },
      enableMaxDateInput: true,
      enableTime: false,
      timePicker: {
        showMeridian: false,
        hourStep: 1,
        minuteStep: 1,
        readonlyInput: false,
        mousewheel: true,
        arrowkeys: true,
      },
      multiple: false,
      defaultValue: "",
      defaultDate: "moment()",
      customOptions: {},
      persistent: false,
      protected: true,
      dbIndex: false,
      encrypted: false,
      redrawOn: "",
      clearOnHide: true,
      customDefaultValue: "value = data.start_date;",
      calculateValue: "",
      calculateServer: false,
      allowCalculateOverride: false,
      validate: {
        required: true,
        customMessage: "",
        custom: "valid = !(moment(data['start_date_protected']).isSameOrBefore(data['next_action_date_protected'])) || !(moment(data['start_date_protected']).isSameOrBefore(data['end_date_protected'])) ? ' ' :true;",
        customPrivate: false,
        json: "",
        strictDateValidation: false,
        multiple: false,
        unique: false,
      },
      unique: false,
      validateOn: "change",
      errorLabel: "",
      key: "start_date_protected",
      tags: [],
      properties: {},
      conditional: {
        show: null,
        when: null,
        eq: "",
        json: "",
      },
      customConditional: "",
      logic: [],
      attributes: {},
      overlay: {
        style: "",
        page: "",
        left: "",
        top: "",
        width: "",
        height: "",
      },
      type: "datetime",
      labelWidth: 10,
      timezone: "",
      input: true,
      widget: {
        type: "calendar",
        displayInTimezone: "viewer",
        locale: "en",
        useLocaleSettings: false,
        allowInput: true,
        mode: "single",
        enableTime: false,
        noCalendar: false,
        format: "yyyy-MM-dd",
        hourIncrement: 1,
        minuteIncrement: 1,
        time_24hr: true,
        minDate: "moment().calendar(); ",
        disabledDates: "",
        disableWeekends: false,
        disableWeekdays: false,
        disableFunction: '{\n    disable: [data.next_action_date_protected,data.end_date_protected ],\n    dateFormat: "Y-m-d",\n}',
        maxDate: "moment().add(data.end_date_protected).calendar();  ",
      },
      hideOnChildrenHidden: false,
      prefix: "",
      suffix: "",
      refreshOn: "",
      dataGridLabel: false,
      showCharCount: false,
      showWordCount: false,
      allowMultipleMasks: false,
      datepickerMode: "day",
      id: "e3fmmv",
    },   {
      "label": "Start Date",
      "redrawOn": "created_on",
      "customDefaultValue": "let now = moment(data.created_on).toISOString();\nvalue = now.split('T')[0];\nconsole.log(value);\\\nconsole.log(data.userprofile);",
      "key": "start_date",
      "properties": {
        "data_type": "Date"
      },
      "type": "hidden",
      "tableView": false,
      "input": true,
      "dataGridLabel": false,
      "hideOnChildrenHidden": false,
      "placeholder": "",
      "prefix": "",
      "customClass": "",
      "suffix": "",
      "multiple": false,
      "defaultValue": null,
      "protected": false,
      "unique": false,
      "persistent": true,
      "hidden": false,
      "clearOnHide": true,
      "refreshOn": "",
      "modalEdit": false,
      "labelPosition": "top",
      "description": "",
      "errorLabel": "",
      "tooltip": "",
      "hideLabel": false,
      "tabindex": "",
      "disabled": false,
      "autofocus": false,
      "dbIndex": false,
      "calculateValue": "",
      "calculateServer": false,
      "widget": {
        "type": "input"
      },
      "attributes": {},
      "validateOn": "change",
      "validate": {
        "required": false,
        "custom": "",
        "customPrivate": false,
        "strictDateValidation": false,
        "multiple": false,
        "unique": false
      },
      "conditional": {
        "show": null,
        "when": null,
        "eq": ""
      },
      "overlay": {
        "style": "",
        "left": "",
        "top": "",
        "width": "",
        "height": ""
      },
      "allowCalculateOverride": false,
      "encrypted": false,
      "showCharCount": false,
      "showWordCount": false,
      "allowMultipleMasks": false,
      "inputType": "hidden",
      "id": "edykahg"
    }
  ];
  export default {components};
  