const components = [
    // {
    //   "input": true,
    //   "tableView": true,
    //   "label": "Next Action Date",
    //   "key": "columns3NextActionDate",
    //   "datePicker": {
    //     "datepickerMode": "day"
    //   },
    //   "validate": {
    //     "required": true,
    //     "unique": false,
    //     "multiple": false
    //   },
    //   "type": "datetime",
    //   "hideOnChildrenHidden": false,
    //   "suffix": "<i ref=\"icon\" class=\"fa fa-calendar\" style=\"\"></i>",
    //   "widget": {
    //     "type": "calendar",
    //     "displayInTimezone": "viewer",
    //     "language": "en",
    //     "useLocaleSettings": false,
    //     "allowInput": true,
    //     "mode": "single",
    //     "enableTime": true,
    //     "noCalendar": false,
    //     "format": "yyyy-MM-dd hh:mm a",
    //     "hourIncrement": 1,
    //     "minuteIncrement": 1,
    //     "time_24hr": false,
    //     "minDate": null,
    //     "maxDate": null
    //   }
    // }
    {
      "label": "Next Action Date",
      "format": "yyyy-MM-dd HH:mm",
      "placeholder": "Next Action Date",
      "tooltip": "Please select next action date of the task",
      "tabindex": "4",
      "tableView": false,
      "enableMinDateInput": true,
      "datePicker": {
        "minDate": "moment().add(data.start_date_protected).calendar();",
        "maxDate": "moment().add(data.end_date_protected).calendar();",
        "disableWeekends": false,
        "disableWeekdays": false,
        "datepickerMode": "day",
        "showWeeks": true,
        "startingDay": 0,
        "initDate": "",
        "minMode": "day",
        "maxMode": "year",
        "yearRows": 4,
        "yearColumns": 5
      },
      "enableMaxDateInput": true,
      "timePicker": {
        "showMeridian": false,
        "hourStep": 1,
        "minuteStep": 1,
        "readonlyInput": false,
        "mousewheel": true,
        "arrowkeys": true
      },
      "persistent": false,
      "protected": true,
      "customDefaultValue": "value = data.start_date;",
      "validate": {
        "required": true,
        "custom": "valid = !(moment(data['next_action_date_protected']).isAfter(data['start_date_protected'])) || !(moment(data['next_action_date_protected']).isBefore(data['end_date_protected'])) ? ' ' :true;",
        "customPrivate": false,
        "strictDateValidation": false,
        "multiple": false,
        "unique": false
      },
      "key": "next_action_date_protected",
      "type": "datetime",
      "input": true,
      "hideOnChildrenHidden": false,
      "suffix": "<i ref=\"icon\" class=\"fa fa-calendar\" style=\"\"></i>",
      "widget": {
        "type": "calendar",
        "displayInTimezone": "viewer",
        "locale": "en",
        "useLocaleSettings": false,
        "allowInput": true,
        "mode": "single",
        "enableTime": true,
        "noCalendar": false,
        "format": "yyyy-MM-dd HH:mm",
        "hourIncrement": 1,
        "minuteIncrement": 1,
        "time_24hr": true,
        "minDate": "moment().add(data.start_date_protected).calendar();",
        "disableWeekends": false,
        "disableWeekdays": false,
        "maxDate": "moment().add(data.end_date_protected).calendar();"
      },
      "prefix": "",
      "customClass": "",
      "multiple": false,
      "defaultValue": "",
      "unique": false,
      "hidden": false,
      "clearOnHide": true,
      "refreshOn": "",
      "redrawOn": "",
      "modalEdit": false,
      "labelPosition": "top",
      "description": "",
      "errorLabel": "",
      "hideLabel": false,
      "disabled": false,
      "autofocus": false,
      "dbIndex": false,
      "calculateValue": "",
      "calculateServer": false,
      "attributes": {},
      "validateOn": "change",
      "conditional": {
        "show": null,
        "when": null,
        "eq": ""
      },
      "overlay": {
        "style": "",
        "left": "",
        "top": "",
        "width": "",
        "height": ""
      },
      "allowCalculateOverride": false,
      "encrypted": false,
      "showCharCount": false,
      "showWordCount": false,
      "properties": {},
      "allowMultipleMasks": false,
      "useLocaleSettings": false,
      "allowInput": true,
      "enableDate": true,
      "enableTime": true,
      "defaultDate": "",
      "displayInTimezone": "viewer",
      "timezone": "",
      "datepickerMode": "day",
      "customOptions": {},
      "id": "e4uws01s"
    }
    ,
    {
      "label": "Next Action Date",
      "redrawOn": "columns3NextActionDate",
      "customDefaultValue": "let now = moment(data.next_action_date_protected).toISOString();\nvalue = now.split('T')[0];\nconsole.log(value);\\\nconsole.log(data.userprofile);",
      "key": "next_action_date",
      "properties": {
        "data_type": "Date"
      },
      "type": "hidden",
      "tableView": false,
      "input": true,
      "dataGridLabel": false,
      "hideOnChildrenHidden": false,
      "placeholder": "",
      "prefix": "",
      "customClass": "",
      "suffix": "",
      "multiple": false,
      "defaultValue": null,
      "protected": false,
      "unique": false,
      "persistent": true,
      "hidden": false,
      "clearOnHide": true,
      "refreshOn": "",
      "modalEdit": false,
      "labelPosition": "top",
      "description": "",
      "errorLabel": "",
      "tooltip": "",
      "hideLabel": false,
      "tabindex": "",
      "disabled": false,
      "autofocus": false,
      "dbIndex": false,
      "calculateValue": "",
      "calculateServer": false,
      "widget": {
        "type": "input"
      },
      "attributes": {},
      "validateOn": "change",
      "validate": {
        "required": false,
        "custom": "",
        "customPrivate": false,
        "strictDateValidation": false,
        "multiple": false,
        "unique": false
      },
      "conditional": {
        "show": null,
        "when": null,
        "eq": ""
      },
      "overlay": {
        "style": "",
        "left": "",
        "top": "",
        "width": "",
        "height": ""
      },
      "allowCalculateOverride": false,
      "encrypted": false,
      "showCharCount": false,
      "showWordCount": false,
      "allowMultipleMasks": false,
      "inputType": "hidden",
      "id": "eu88ua8"
    }
    ,
    // {
    //   "label": "HTML",
    //   "attrs": [
    //     {
    //       "attr": "",
    //       "value": ""
    //     }
    //   ],
    //   "content": "Next action date cannot be less than Start date or greater than End date",
    //   "refreshOnChange": false,
    //   "hidden": true,
    //   "key": "nadvalidation",
    //   "customConditional": "show = !(moment(data['start_date_protected']).isSameOrBefore(data['next_action_date_protected'])) || !(moment(data['next_action_date_protected']).isSameOrBefore(data['end_date_protected']));",
    //   "type": "htmlelement",
    //   "input": false,
    //   "tableView": false,
    //   "hideOnChildrenHidden": false,
    //   "dataGridLabel": false,
    //   "placeholder": "",
    //   "prefix": "",
    //   "customClass": "",
    //   "suffix": "",
    //   "multiple": false,
    //   "defaultValue": null,
    //   "protected": false,
    //   "unique": false,
    //   "persistent": false,
    //   "clearOnHide": true,
    //   "refreshOn": "",
    //   "redrawOn": "",
    //   "modalEdit": false,
    //   "labelPosition": "top",
    //   "description": "",
    //   "errorLabel": "",
    //   "tooltip": "",
    //   "hideLabel": false,
    //   "tabindex": "",
    //   "disabled": false,
    //   "autofocus": false,
    //   "dbIndex": false,
    //   "customDefaultValue": "",
    //   "calculateValue": "",
    //   "calculateServer": false,
    //   "widget": null,
    //   "attributes": {},
    //   "validateOn": "change",
    //   "validate": {
    //     "required": false,
    //     "custom": "",
    //     "customPrivate": false,
    //     "strictDateValidation": false,
    //     "multiple": false,
    //     "unique": false
    //   },
    //   "conditional": {
    //     "show": null,
    //     "when": null,
    //     "eq": ""
    //   },
    //   "overlay": {
    //     "style": "",
    //     "left": "",
    //     "top": "",
    //     "width": "",
    //     "height": ""
    //   },
    //   "allowCalculateOverride": false,
    //   "encrypted": false,
    //   "showCharCount": false,
    //   "showWordCount": false,
    //   "properties": {},
    //   "allowMultipleMasks": false,
    //   "tag": "p",
    //   "id": "eanoxk",
    //   "keyModified": true
    // }
  ];
  export default { components };
  