import React, { Component, createElement } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { Card, Button } from "react-bootstrap";
import "./public/css/documentViewer.scss";
import { Upload } from "@progress/kendo-react-upload";
import Notification from "./Notification";
import Requests from "./Requests";
import Popup from 'reactjs-popup';

export default class DocumentViewer extends Component {
  constructor(props) {
    super(props);
    this.core = this.props.core;
    this.appId = this.props.appId;
    this.state = {
      apiCallStatus: false,
      selectedDocument: undefined,
      documentsList: undefined,
      documentTypes: [],
      activeCard: "",
      uploadFiles: [],
      showCreatePopup: false,
      fileType: "",
      fileName: "",
    };
    this.loader = this.core.make("oxzion/splash");
    this.helper = this.core.make("oxzion/restClient");
    this.baseUrl = this.core.config("wrapper.url");
    this.viewUrl = this.core.config("ui.url");
    this.notif = React.createRef();
    var urlRes = this.props.url.split("/");
    this.fileId = urlRes[urlRes.length - 2];
    this.getDocumentsList = this.getDocumentsList.bind(this);
    this.getDocumentsList();
  }

  onFileChange = (event) => {
    let fileError = false;
    let validFiles = event.newState.filter((item) => {
      if (item.validationErrors) {
        if (item.validationErrors.length > 0) {
          fileError = true;
          return false;
        }
      } else {
        return true;
      }
    });

    if (validFiles) {
      this.setState({
        uploadFiles: validFiles,
      });
    }
    fileError
      ? this.notif.current.notify(
          "Unsupported File",
          "Please choose a different file.",
          "danger"
        )
      : null;
  };

  showFile(url) {
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", url, false);
    rawFile.onreadystatechange = function () {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status == 0) {
          var allText = rawFile.responseText;
          return allText;
        }
      }
    };
    rawFile.send(null);
    return rawFile.onreadystatechange();
  }

  async downloadAllAttachments(attachments = []) {
    if (attachments.length == 0) return;
    const fetchBlob = async (attachment) => {
      let attachmentPath = !attachment.file && (attachment?.path).split("/file_docs/")[1];
      console.log("attchpayh",attachmentPath)
      let filepathUrl = this.baseUrl +
        "app/" +
        this.appId +
        "/document/" +
        attachment.originalName +
        "?docPath=" +
        (attachment?.file || attachmentPath);

      return new Promise(async r => {
        try {
          r({ ...attachment, blob: URL.createObjectURL((await fetch(filepathUrl).then(v => v.blob()))) })
        } catch (e) {
          r(attachment)
        }
      })
    }
    this.loader.show()
    const attachmentBlobs = await Promise.all(attachments.map((attachment) => fetchBlob(attachment)));
    this.loader.destroy()
    for (let i = 0; i < attachmentBlobs.length; i++) {
      if (!attachmentBlobs[i].blob) continue;
      const anchor = document.createElement('a');
      anchor.href = attachmentBlobs[i].blob;
      anchor.download = attachmentBlobs[i].originalName;
      anchor.click()
    }
  }

  navigateOrDownload(e, doc, url){
    e.stopPropagation();
    if(doc?.file?.trim()?.length === 0 || !doc.file){
      window.open(url || doc.url, '_blank').focus();
      return
    }
    const anchor = document.createElement('a');
    anchor.href = url || doc.url;
    anchor.download = doc.name;
    anchor.target =  "_blank";
    anchor.click();
  }

   async uploadAttachments(files) {
    this.loader.show();
    await Promise.all(files.map((file) => this.postAttachments(file))).then(      
      async  (uploadedFiles) => {
        this.loader.destroy();
        const failedFiles = uploadedFiles
          .filter((failedFile) => failedFile?.status != "success")
          .map(({message}) => {
            this.notif.current.notify(message || 'Failed to upload', "Please choose a different file.","danger")
            return true;
          });
        if (failedFiles.length > 0) {
          if(failedFiles.length === files.length) return;
          await new Promise(r => setTimeout(r, 2000))
        }
        // alert("uploadattch")
        this.setState(
          {
            selectedDocument: undefined,
            documentsList: undefined,
            documentTypes: [],
            activeCard: "",
            uploadFiles: [],
          },
          this.getDocumentsList
        );
      }
    ).catch((e) => {
      this.loader.destroy();
        this.setState(
          {
            selectedDocument: undefined,
            documentsList: undefined,
            documentTypes: [],
            activeCard: "",
            uploadFiles: [],
          },
          this.getDocumentsList()
        );
    })
  }
   sleep(miliseconds) {
    var currentTime = new Date().getTime(); 
    while (currentTime + miliseconds >= new Date().getTime()) {
       
    }
 }
  async postAttachments(file, createFile) {
    this.sleep(500);
    var urlRes = this.props.url.split("/");
    var fileId = urlRes[urlRes.length - 2];
  //  await new Promise((resolve) => setTimeout(resolve, 1000));
    let response = await this.helper.request(
      "v1",
      "/app/" + this.appId + "/file/attachment",
      {
        file: !createFile ? file.getRawFile() : file,
        name: file.name,
        type: !createFile ? "file/" + file.extension.split(".")[1] : "file/" + file.name.split(".")[1],
        fileId: fileId,
        fieldLabel: this.state.activeCard,
      },
      "filepost"
    );
    return response;
  }
  appendUrlIfNotExist(attachmentTypes) {
    try {
      for (let attachmentType in attachmentTypes) {
        let attachments = attachmentTypes[attachmentType]?.value || [];
        attachments?.forEach((attachment, index) => {
          if(!attachment.file && attachmentTypes[attachmentType]['app_docs_folder']){
            attachment['file'] = attachment.path.replace(attachmentTypes[attachmentType]['app_docs_folder'], "");
          }
          if (!attachment.url && attachment.file) {
            attachments[index]["url"] = `${this.baseUrl}${attachment.file}`
            attachments[index]["id"] =
              attachments[index]["id"] || attachment.file;
          }
          if(!attachment?.id || attachment?.id && `${attachment?.id}`?.trim()?.length === 0){
            attachments[index]["id"] = `${math.random()}${math.random()}`;
          }
        });
      }
    } catch (e) {}
  }
  getDocumentsList = () => {
    if (this.props.url) {
      this.loader.show();
      Requests.getDocumentsListService(
        this.core,
        this.appId,
        this.props.url
      ).then((response) => {
        if (response.data) {
          this.appendUrlIfNotExist(response.data);
          var documentsList = {};
          var folderType = {};
          var documentTypes = Object.keys(response.data);
          documentTypes.map((docType) => {
            if (response.data[docType]) {
              if (response.data[docType].value && response.data[docType].type) {
                if (response.data[docType].value.length > 0) {
                  documentsList[docType] = response.data[docType].value;
                  folderType[docType] = response.data[docType].type;
                } else if (response.data[docType].type == "file") {
                  documentsList[docType] = [];
                  folderType[docType] = response.data[docType].type;
                }
              }
            }
          });
          if (Object.keys(documentsList).length > 0) {
            var validDocTypes = Object.keys(documentsList);
            validDocTypes = validDocTypes
              .sort((a, b) => a.localeCompare(b))
              .filter((item) => item !== "Documents");
            documentsList.Documents ? validDocTypes.unshift("Documents") : null;
            // alert("setstate");
            this.setState({
              apiCallStatus: true,
              documentsList: documentsList,
              folderType: folderType,
              selectedDocument: documentsList[validDocTypes[0]][0],
              activeCard: validDocTypes[0],
              documentTypes: validDocTypes,
            });
          } else {
            this.setState({
              apiCallStatus: true,
            });
          }
          this.loader.destroy();
        }
      });
    } else {
      this.setState({
        apiCallStatus: true,
      });
    }
  };

  componentDidMount() {
    if (this.state.selectedDocument) {
      var documentViewerDiv = document.querySelector(".docViewerWindow");
      this.loader.show(documentViewerDiv);
    }
  }

  generateDocumentList() {
    var accordionHTML = [];
    if (this.state.documentTypes) {
      this.state.documentTypes.map((docType, index) => {
        this.state.documentsList[docType]
          ? accordionHTML.push(
              <Card key={index}>
                <Card.Header className={"border-0"}>
                  <CustomToggle
                    eventKey={docType}
                    currentSelected={this.state.activeCard}
                    type={this.state.folderType[docType]}
                    update={(item) => {
                      this.state.documentsList[item].length !== 0
                        ? this.setState({
                            activeCard: item,
                            selectedDocument: this.state.documentsList[item][0],
                          })
                        : this.setState({
                            activeCard: item,
                          });
                    }}
                  >
                    {docType}
                  </CustomToggle>
                </Card.Header>
              <Accordion.Collapse eventKey={docType}>
                <Card.Body>
                  {
                    // this.state.selectedDocument.extension == 'docx' &&
                    //  this.state.showCreatePopup == 'false' &&
                    <Popup
                      trigger={
                        // this.state.selectedDocument.extension == 'docx'&& 
                        <button
                          title="Create"
                          className="btn btn-primary btn-dark"
                          onClick={(e) => { 
                            this.setState({
                              showCreatePopup: true,
                            });
                            // document.getElementsByClassName("fileNameCreateDivPopup").classList.remove("display-none");
                            
                          }}
                        >
                          <i className="fas fa-plus"></i>
                        </button>}
                      position="right center"
                      closeOnDocumentClick
                      closeOnEscape
                    >
                      {
                        // this.state.showCreatePopup == true &&  
                        <div className="fileNameCreateDivPopup p-2 border p-10px  border-gray-300 rounded-md">
                          <div className="flex flex-col gap-1 w-auto flex-1">
                            <label className="file-name-create text-dark">
                              File Name
                            </label>
                            <input
                              className="p-2 border p-10px  border-gray-300 rounded-md"
                              type="text"
                              placeholder="File name"
                              onChange={(event) => {
                                this.setState({
                                  fileName: event.target.value
                                });
                              }}
                              value={this.state.fileName}
                            />
                          </div>
                          <div className="flex flex-col gap-1 w-auto flex-1">
                            <label className="type-of-file text-dark">Type of File</label>
                            <select
                              value={this.state.fileType}
                              placeholder="Choose type of file"
                              className="border-red-800 p-2 border border-gray-300 rounded-md bg-white"
                              onChange={(event) => {
                                this.setState({
                                  fileType: event.target.value
                                });
                              }}
                            >
                              <option value=""> </option>
                              <option key="Document" value="docx">Document</option>
                              <option key="SpreadSheet" value="xlsx">SpreadSheet</option>
                              <option key="Presentation" value="pptx">Presentation</option>

                            </select>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary mt-2 w-40 p-1 d-inline-block"
                            onClick={ async (e) => {
                              e.preventDefault();
                              this.loader.show();
                              let fileNameFull = `${this.state.fileName}.${this.state.fileType}`;
                              let fetchedFileData = await fetch(`${this.viewUrl}template/new.${this.state.fileType}`);
                              var data = await fetchedFileData.blob();
                              var dummyfile = new File([data], fileNameFull);
                              this.setState({
                                showCreatePopup: false,
                              });
                              this.postAttachments(dummyfile, true).then((value) => {
                                if (value.status == "success") {
                                  this.notif.current.notify(
                                    "Success",
                                    value.status,
                                    "success"
                                  );
                                  this.setState(
                                    {
                                      selectedDocument: undefined,
                                      documentsList: undefined,
                                      documentTypes: [],
                                      activeCard: "",
                                      uploadFiles: [],
                                    },
                                    this.getDocumentsList()
                                  );
                                  this.core.run('EOXOffice', {'appId':this.appId, 'fileId':this.fileId, 'docData':value.data});
                                } else {
                                  this.notif.current.notify(
                                    "Error",
                                    value.status,
                                    "danger"
                                  );
                                }
                              })
                            }}
                          >
                            Create
                          </button>
                        </div>}
                    </Popup>
                  }
                  {this.state.documentsList[docType].map((doc, i) => {
                    const fileName = doc.name?.trim()?.length > 0 && doc.name.replace(`-${doc.uuid}`, '') || doc.originalName;
                    return (
                      <Card
                        className="docItems"
                        onClick={(e) => {
                          this.handleDocumentClick(doc)
                          // doc.file != this.state.selectedDocument
                          //   ? this.state.selectedDocument.file
                          //     ? this.handleDocumentClick(doc)
                          //     : null
                          //   : null;
                        }}
                        key={i}
                      >
                        <div
                          className={
                            this.state.selectedDocument &&
                              // this.state.selectedDocument.file
                              //   ? doc.file == this.state.selectedDocument.file
                              this.state.selectedDocument.id
                              ? doc.id == this.state.selectedDocument.id
                                ? "docListBody borderActive"
                                : "docListBody border"
                              : "docListBody border"
                          }
                        >
                          <i
                            className={"docIcon " + this.getDocIcon(doc.type)}
                          ></i>
                          <p>
                            
                              {fileName &&
                                fileName.length > 30
                                ? this.chopFileName(fileName)
                                : fileName}
                            
                          </p>
                        </div>
                      </Card>
                    );
                  })}                
{this.state.folderType[docType] == "file" || this.state.folderType[docType] == "document" ? (
                    <div className="popupWindow">
                      {!this.readOnly && <Upload
                        batch={false}
                        multiple={true}
                        autoUpload={false}
                        files={this.state.uploadFiles}
                        onAdd={this.onFileChange}
                        onRemove={this.onFileChange}
                        restrictions={{
                          allowedExtensions:['.txt', '.text', '.gif', '.png', '.jpg', '.zip', '.mp4', '.mov', '.mp3', '.pdf', '.doc', '.docx', '.ppt', '.pptx', '.csv', '.dif', '.xls', '.xlsx', '.xlsm', '.xps', '.rtf', '.odt', '.dotx', '.dotm', '.dot', '.docm', '.tz', '.gzip', '.rar', '.ods', '.prn', '.xla', '.xlam', '.xlt', '.xltm', '.xltx', '.bmp', '.emf', '.pot', '.potm', '.potx', '.ppa', '.ppam', '.pps', '.ppsx', '.tif', '.wmv', '.xml', '.wmf'],
                          maxFileSize: 25000000,
                        }}
                      />}
                      <div className="display-flex">
                        {
                          (this.state.documentsList[docType].length > 0) &&
                          <button className="uploadButton" onClick={() => this.downloadAllAttachments(this.state.documentsList[docType])}>
                            Download All
                            </button>
                        }
                        {!this.readOnly && <button
                          className={
                            this.state.uploadFiles.length == 0
                              ? "uploadButton invalidButton"
                              : "uploadButton"
                          }
                          disabled={this.state.uploadFiles.length == 0}
                          onClick={(e) => {
                            e.preventDefault();
                            this.loader.show();
                            this.uploadAttachments(
                              this.state.uploadFiles
                            );
                          }}
                        >
                          Upload
                          </button>}
                      </div>
                    </div>
                  ) : null}

                  {/* ) : null} */}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
          : null;
      });
    }
    return accordionHTML;
  }

  getDocIcon(type) {
    try {
      var type = type.split("/")[1].toLowerCase();
      if (type == "png" || type == "jpg" || type == "jpeg" || type == "gif") {
        return "fa fa-picture-o";
      } else if (type == "pdf") {
        return "fa fa-file-pdf-o";
      } else if (type == "mp4" || type == "avi") {
        return "fa fa-file-video-o";
      } else if (type == "plain") {
        return "fas fa-file-alt";
      } else if (
        type == "odt" ||
        type == "odp" ||
        type == "ods" ||
        type == "doc" ||
        type == "docx"
      ) {
        return "fa fa-file-word-o";
      } else {
        return "far fa-file";
      }
    } catch (Exception) {
      return "far fa-file";
    }
  }

  chopFileName = (title) => {
    let type = "...." + title.split(".")[1];
    var displayTitle = title.substring(0, 26) + type;
    return displayTitle;
  };

  getValidDownloadUrl = async (document) => {
    return new Promise(async (resolve) => {
      try{
        const {url, originalName, file} = document;
        const docUrl = `${this.baseUrl}app/${this.appId}/document/${originalName}?docPath=${file}`;
        const fetchDoc = (url) => {
          return new Promise((r) =>{
            fetch(url).then((response) =>{
              r(response.status == 200)
            }).catch((e) => r(false))
          })
        }
        if(await fetchDoc(docUrl)) return resolve(document);
        document['validDownloadUrl'] = url;
        resolve(document)
      }catch(e){
        resolve(document)
      }
    })
  }

  handleDocumentClick = async (doc) => {
    if(this.state.selectedDocument?.id === doc.id) return;
    var documentViewerDiv = document.querySelector(".docViewerWindow");
    this.loader.show(documentViewerDiv);
    this.setState({
      selectedDocument: await this.getValidDownloadUrl(doc),
    });
  };

  attachmentOperations(documentData, rename, del, downloadUrl) {
    return (
      <div className="row" style={{display : 'unset'}}>
        <div className="col-md-12 dash-manager-buttons w-100">
          {rename &&
          this.state.activeCard != "Documents" &&
          documentData.uuid ? (
            <>
              <input
                type="text"
                id="filename"
                className="form-control"
                value={this.state.selectedDocument.originalName.split(".")[0]}
                onChange={(e) => {
                  const edited = { ...this.state.selectedDocument };
                  edited["originalName"] =
                    e.target.value +
                    "." +
                    this.state.selectedDocument.originalName.split(".")[1];
                  this.setState({
                    selectedDocument: edited,
                  });
                }}
              />
              <button
                title="rename"
                className="btn btn-primary btn-dark"
                onClick={() => {
                  Requests.renameFile(
                    this.core,
                    this.appId,
                    this.fileId,
                    documentData.uuid,
                    this.state.selectedDocument.originalName,
                    documentData.name,
                    documentData.url,
                    documentData.path
                  ).then((response) => {
                    if (response.status == "success") {
                      this.notif.current.notify(
                        "Success",
                        response.message,
                        "success"
                      );
                      this.setState(
                        {
                          selectedDocument: undefined,
                          documentsList: undefined,
                          documentTypes: [],
                          activeCard: "",
                          uploadFiles: [],
                        },
                        this.getDocumentsList()
                      );
                    } else {
                      this.notif.current.notify(
                        "Error",
                        response.message,
                        "danger"
                      );
                    }
                  });
                }}
              >
                <i className="fas fa-file-edit"></i>
              </button>
            </>
          ) : null}
          {del && this.state.activeCard != "Documents" && documentData.uuid ? (
            <button
              title="delete"
              className="btn btn-primary btn-dark"
              onClick={() => {
                Requests.deleteFile(
                  this.core,
                  this.appId,
                  this.fileId,
                  documentData.uuid,
                  documentData.name
                ).then((response) => {
                  if (response.status == "success") {
                    this.notif.current.notify(
                      "Success",
                      response.message,
                      "success"
                    );
                    this.setState(
                      {
                        selectedDocument: undefined,
                        documentsList: [],
                        documentTypes: [],
                        activeCard: "",
                        uploadFiles: [],
                      },
                      this.getDocumentsList()
                    );
                  } else {
                    this.notif.current.notify(
                      "Error",
                      response.message,
                      "danger"
                    );
                  }
                });
              }}
            >
              <i className="fas fa-trash"></i>
            </button>
          ) : null}
          {downloadUrl ? (
            <a
              // href={downloadUrl}
              // download
              // target="_blank"
              className="image-download-button btn btn-primary"
              title="Download"
              onClick={e => this.navigateOrDownload(e, this.state.selectedDocument, downloadUrl)}
            >
              <i className="fas fa-download" aria-hidden="true"></i>
            </a>
          ) : null}
          {(this.state.selectedDocument.extension == 'docx' || 
            this.state.selectedDocument.extension == 'doc'|| 
            this.state.selectedDocument.extension == 'xlsx' || 
            this.state.selectedDocument.extension == 'xls' || 
            this.state.selectedDocument.extension == 'pptx' || 
            this.state.selectedDocument.extension == 'ppt') &&
            <button
              title="Edit"
              className="btn btn-primary btn-dark"
              onClick={() => {
                this.core.run('EOXOffice', {'appId':this.appId, 'fileId':this.fileId, 'docData':this.state.selectedDocument});
              }}
            >
              <i className="fas fa-pencil"></i>
            </button>
          }
        </div></div>
    );
  }

  displayDocumentData = (documentData) => {
    var url;
    this.loader.destroy();
    var type = documentData?.type?.split?.("/")?.[1].toLowerCase();
    if (type == "png" || type == "jpg" || type == "jpeg" || type == "gif") {
      url =
        this.baseUrl +
        "app/" +
        this.appId +
        "/document/" +
        documentData.originalName +
        "?docPath=" +
        documentData.file;
      return (
        <React.Fragment>
          {this.attachmentOperations(documentData, true, true, url)}
          <div className="row" style={{display : 'unset'}}>
            <div className="col-md-12 dash-manager-buttons w-100">
              <img
                onLoad={() => this.loader.destroy()}
                className="img-fluid"
                style={{ height: "100%" }}
                src={documentData.validDownloadUrl ||url}
              />
            </div>
          </div>
        </React.Fragment>
      );
    } else if (type == "mp4" || type == "avi") {
      url =
        this.baseUrl +
        "app/" +
        this.appId +
        "/document/" +
        documentData.originalName +
        "?docPath=" +
        documentData.file;
      return (
        <div>
          {this.attachmentOperations(documentData, true, true, url)}
          <video
            autoplay
            muted
            preload
            controls
            width="100%"
            onCanPlay={this.loader.destroy()}
          >
            <source src={documentData.validDownloadUrl ||url} type={"video/" + type} />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      );
    } else if (type == "pdf") {
      const fileEmpty = !documentData.file || documentData?.file?.trim()?.length === 0;
      if(fileEmpty) this.loader.destroy()
      url =
        // this.core.config("ui.url") +
        // "/ViewerJS/#" +
        this.baseUrl +
        "app/" +
        this.appId +
        "/document/" +
        documentData.originalName +
        "?docPath=" +
        documentData.file;
      return (
        <div className="pdf-frame">
          {this.attachmentOperations(documentData, true, true)}
         {!fileEmpty && <iframe
            onLoad={() => {
              setTimeout(() => {
                this.loader.destroy();
              }, 800);
            }}
            key={Math.random() * 20}
            src={documentData.validDownloadUrl ||url}
            className="iframeDoc"
          ></iframe>}
        </div>
      );
    } else if (type == "plain") {
      url =
        this.baseUrl +
        "app/" +
        this.appId +
        "/document/" +
        documentData.originalName +
        "?docPath=" +
        documentData.file;
      this.loader.destroy();
      return (
        <React.Fragment>
          {this.attachmentOperations(documentData, true, true, url)}
          <div className="show-text col-md-12">{this.showFile(url)}</div>
        </React.Fragment>
      );
    } else {
      url =
        this.baseUrl +
        "app/" +
        this.appId +
        "/document/" +
        documentData.originalName +
        "?docPath=" +
        documentData.file;
      var url2 =
        this.core.config("ui.url") + "/ViewerJS/images/unsupported_file.jpg";
      this.loader.destroy();
      return (
        <React.Fragment>
          {this.attachmentOperations(documentData, true, true, url)}
          <div className="row">
            <div className="col-md-12">
              <img
                className="img-fluid"
                style={{ height: "100%" }}
                src={url2}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  render() {
    const { documentsList } = this.state;
    if (this.state.apiCallStatus) {
      if (documentsList) {
        return (
          <div className="docViewerComponent">
            <Notification ref={this.notif} />
            <div className="col-md-3 docListDiv docViewerComponent_docListDiv">
              <Accordion defaultActiveKey={this.state.documentTypes[0]}>
                {this.generateDocumentList()}
              </Accordion>
            </div>
            <div className="col-md-9 border docViewerWindow">
              {this.state.selectedDocument ? (
                this.displayDocumentData(this.state.selectedDocument)
              ) : (
                <p>No files to display.</p>
              )}
            </div>
          </div>
        );
      } else {
        return <p>No files to display.</p>;
      }
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.getDocumentsList();
    }
  }
}

function CustomToggle(props) {
  return (
    <Button
      variant="primary"
      onClick={
        props.currentSelected !== props.eventKey
          ? useAccordionToggle(props.eventKey, () =>
              props.update.call(undefined, props.eventKey)
            )
          : null
      }
    >
      <i
        className={
          props.currentSelected == props.eventKey
            ? "docIcon fa fa-caret-right rotate90"
            : "docIcon fa fa-caret-right"
        }
      ></i>
      {props.children}
    </Button>
  );
}
