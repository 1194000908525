const components = [
	{
		"label": "Fax Number",
		"placeholder": " ",
		"tabindex": "16",
		"autocomplete": "off",
		"tableView": true,
		"validate": {
		  "custom": "if(data['fax'] && data['fax'] != ''){\n  if(data['fax'].length <= 15){\n      valid = (data['fax'].match(/^\\d+$/)) ?  true : 'Please enter a valid Fax number';\n  }else{\n    valid = (data['fax'].length > 15) ? 'Please enter a valid Fax number' : true;\n  }\n}"
		},
		"key": "fax",
		"attributes": {
		  "style": "text-align:right"
		},
		"type": "textfield",
		"input": true,
		"lockKey": true,
		"hideOnChildrenHidden": false,
		"inputType": "tel"
	  }
];
export default { components };
