const components = [
  {
    label: "Assigned to",
    widget: "choicesjs",
    labelWidth: 7,
    labelMargin: 1,
    placeholder: "Assigned to",
    tabindex: "1",
    tableView: false,
    dataSrc: "custom",
    data: {
      custom: "values = data.userlist;",
    },
    template: "<span>{{ item.name }}</span>",
    clearOnHide: false,
    customDefaultValue: 'if(data.assignedtoObj == ""){\n  var objj = {};\n  if(data.assignedToName != "" && data.assignedto != ""){\n      objj = {name : data.assignedToName,uuid:data.assignedto};\n      value = objj;\n  }\n}\n',
    key: "assignedtoObj",
    properties: {
      absoluteUrl: "true",
    },
    type: "select",
    input: true,
    lockKey: true,
    hideOnChildrenHidden: false,
  },
  {
    label: "assignedToUuid",
    redrawOn: "assignedtoObj",
    calculateValue: "if(data.assignedtoObj.uuid == undefined  && data.userprofile){\n  value = data.userprofile.uuid;\n} else {\n  value = data.assignedtoObj.uuid;\n}",
    key: "assignedto",
    type: "hidden",
    input: true,
    tableView: false,
    hideOnChildrenHidden: false,
  },
  {
    label: "assignedToName",
    redrawOn: "assignedtoObj",
    calculateValue: "if(data.assignedtoObj.name == undefined && data.userprofile){\n  value = data.userprofile.name;\n} else {\n  value = data.assignedtoObj.name;\n}",
    key: "assignedToName",
    type: "hidden",
    input: true,
    tableView: false,
    hideOnChildrenHidden: false,
  },
];
export default { components };
