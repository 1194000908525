const components = [
	{
		"label": "Department",
		"widget": "choicesjs",
		"tooltip": "Which department is the RCA under?",
		"tabindex": "2",
		"tableView": true,
		"defaultValue": "Admin",
		"data": {
		  "values": [
			{
			  "label": "Admin",
			  "value": "Admin"
			},
			{
			  "label": "HR",
			  "value": "HR"
			},
			{
			  "label": "Product",
			  "value": "Product"
			},
			{
			  "label": "Training",
			  "value": "Training"
			},
			{
			  "label": "Sales",
			  "value": "Sales"
			},
			{
			  "label": "Delivery",
			  "value": "Delivery"
			},
			{
			  "label": "Finance",
			  "value": "Finance"
			},
			{
			  "label": "Quality Assurance",
			  "value": "Quality Assurance"
			},
			{
			  "label": "Quality Control",
			  "value": "Quality Control"
			},
			{
			  "label": "Managed Services",
			  "value": "Managed Services"
			},
			{
			  "label": "Marketing",
			  "value": "Marketing"
			}
		  ],
		  "json": "",
		  "url": "",
		  "resource": "",
		  "custom": ""
		},
		"validate": {
		  "required": true,
		  "onlyAvailableItems": false,
		  "custom": "",
		  "customPrivate": false,
		  "strictDateValidation": false,
		  "multiple": false,
		  "unique": false
		},
		"key": "department",
		"type": "select",
		"input": true,
		"dataGridLabel": false,
		"searchDebounce": 0.3,
		"ignoreCache": false,
		"selectThreshold": 0.3,
		"indexeddb": {
		  "filter": {}
		},
		"useExactSearch": false,
		"hideOnChildrenHidden": false,
		"core": {
		  "name": "Core",
		  "events": {
			"osjs/core:logged-in": [
			  {
				"options": {}
			  }
			],
			"osjs/core:started": [
			  {
				"options": {}
			  },
			  {
				"options": {}
			  },
			  {
				"options": {}
			  },
			  {
				"options": {}
			  },
			  {
				"options": {}
			  },
			  {
				"options": {}
			  },
			  {
				"options": {}
			  }
			],
			"osjs/core:destroy": [
			  {
				"options": {}
			  }
			],
			"osjs/application:socket:message": [
			  {
				"options": {}
			  }
			],
			"osjs/core:connected": [
			  {
				"options": {}
			  }
			],
			"osjs/core:disconnect": [
			  {
				"options": {}
			  }
			],
			"osjs/core:connect": [
			  {
				"options": {}
			  },
			  {
				"options": {}
			  }
			],
			"osjs/core:connection-failed": [
			  {
				"options": {}
			  }
			],
			"osjs/panel:create": [
			  {
				"options": {}
			  }
			],
			"osjs/panel:destroy": [
			  {
				"options": {}
			  }
			],
			"osjs/window:transitionend": [
			  {
				"options": {}
			  }
			],
			"osjs/window:change": [
			  {
				"options": {}
			  },
			  {
				"options": {}
			  }
			],
			"osjs/settings:load": [
			  {
				"options": {}
			  }
			],
			"osjs/settings:save": [
			  {
				"options": {}
			  }
			],
			"oxzion/application:launch": [
			  {
				"options": {}
			  }
			],
			"notification": [
			  {
				"options": {}
			  }
			],
			"osjs/packages:metadata:changed": [
			  {
				"options": {}
			  }
			],
			"destroy": [
			  {
				"options": {
				  "once": true
				}
			  }
			],
			"oxzion/profile:updated": [
			  {
				"options": {}
			  },
			  {
				"options": {}
			  },
			  {
				"options": {}
			  }
			],
			"osjs/application:launch": [
			  {
				"options": {}
			  }
			],
			"osjs/application:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/window:destroy": [
			  {
				"options": {}
			  }
			],
			"osjs/window:render": [
			  {
				"options": {}
			  }
			],
			"osjs/tray:update": [
			  {
				"options": {}
			  },
			  {
				"options": {}
			  },
			  {
				"options": {}
			  }
			],
			"osjs/application:AppStudioTestApp:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:Chat:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:FormTest:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:Mail:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:USHRISApp:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:ISMSForm:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:CRMApp:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:LabradorStudio:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:IndiaOnboarding:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:Announcement:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:QMSForms:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:EOXAppBuilder:launched": [
			  {
				"options": {}
			  }
			],
			"osjs/application:Admin:launched": [
			  {
				"options": {}
			  }
			],
			"admin/announcement:modified": [
			  {
				"options": {}
			  }
			],
			"osjs/core:logout-start": [
			  {
				"options": {}
			  }
			],
			"announcement/tray:modified": [
			  {
				"options": {}
			  }
			]
		  },
		  "logger": {
			"memory": {}
		  },
		  "configuration": {
			"development": false,
			"standalone": false,
			"http": {
			  "public": "/",
			  "uri": "https://qa3.eoxvantage.com/"
			},
			"ws": {
			  "connectInterval": 5000,
			  "uri": "wss://qa3.eoxvantage.com/",
			  "disabled": false
			},
			"languages": {
			  "en_EN": "English"
			},
			"packages": {
			  "manifest": "/metadata.json"
			},
			"application": {
			  "categories": {
				"development": {
				  "label": "LBL_APP_CAT_DEVELOPMENT",
				  "icon": "applications-development"
				},
				"science": {
				  "label": "LBL_APP_CAT_SCIENCE",
				  "icon": "applications-science"
				},
				"games": {
				  "label": "LBL_APP_CAT_GAMES",
				  "icon": "applications-games"
				},
				"graphics": {
				  "label": "LBL_APP_CAT_GRAPHICS",
				  "icon": "applications-graphics"
				},
				"network": {
				  "label": "LBL_APP_CAT_NETWORK",
				  "icon": "applications-internet"
				},
				"multimedia": {
				  "label": "LBL_APP_CAT_MULTIMEDIA",
				  "icon": "applications-multimedia"
				},
				"system": {
				  "label": "LBL_APP_CAT_SYSTEM",
				  "icon": "applications-system"
				},
				"business": {
				  "label": "LBL_APP_CAT_BUSINESS",
				  "icon": "applications-business"
				},
				"finance": {
				  "label": "LBL_APP_CAT_FINANCE",
				  "icon": "applications-finance"
				},
				"news": {
				  "label": "LBL_APP_CAT_NEWS",
				  "icon": "applications-news"
				},
				"medical": {
				  "label": "LBL_APP_CAT_MEDICAL",
				  "icon": "applications-medical"
				},
				"entertainment": {
				  "label": "LBL_APP_CAT_ENTERTAINMENT",
				  "icon": "applications-entertainment"
				},
				"productivity": {
				  "label": "LBL_APP_CAT_PRODUCTIVITY",
				  "icon": "applications-productivity"
				},
				"education": {
				  "label": "LBL_APP_CAT_EDUCATION",
				  "icon": "applications-education"
				},
				"photoVideo": {
				  "label": "LBL_APP_CAT_PHOTOANDVEDIO",
				  "icon": "applications-photoVideo"
				},
				"shopping": {
				  "label": "LBL_APP_CAT_SHOPPING",
				  "icon": "applications-shopping"
				},
				"travel": {
				  "label": "LBL_APP_CAT_TRAVEL",
				  "icon": "applications-travel"
				},
				"oi": {
				  "label": "LBL_APP_CAT_DASHBOARD",
				  "icon": "applications-dashboard"
				},
				"other": {
				  "label": "LBL_APP_CAT_OTHER",
				  "icon": "applications-other"
				},
				"salesandmarketing": {
				  "label": "LBL_APP_CAT_SALES",
				  "icon": "applications-sales"
				},
				"utilities": {
				  "label": "LBL_APP_CAT_UTILITIES",
				  "icon": "applications-utilities"
				},
				"office": {
				  "label": "LBL_APP_CAT_OFFICE",
				  "icon": "applications-office"
				},
				"hrmanagement": {
				  "label": "LBL_APP_CAT_HRMANAGEMENT",
				  "icon": "applications-hrmanagement"
				},
				"collaboration": {
				  "label": "LBL_APP_CAT_COLLABORATION",
				  "icon": "applications-development"
				},
				"employeeCenter": {
				  "label": "LBL_APP_CAT_EMPLOYEECENTER",
				  "icon": "applications-development"
				},
				"organization": {
				  "label": "LBL_APP_CAT_ORGANIZATION",
				  "icon": "applications-development"
				},
				"qms": {
				  "label": "LBL_APP_CAT_QMS",
				  "icon": "applications-development"
				},
				"isms": {
				  "label": "LBL_APP_CAT_ISMS",
				  "icon": "applications-development"
				},
				"operationalIntelligence": {
				  "label": "LBL_APP_CAT_OPERATIONALINTELLIGENCE",
				  "icon": "applications-development"
				},
				"salesMarketing": {
				  "label": "LBL_APP_CAT_SALESANDMARKETING",
				  "icon": "applications-development"
				}
			  }
			},
			"auth": {
			  "ui": {
				"title": "Welcome to EOS",
				"logo": {
				  "position": "top",
				  "src": {
					"default": "https://qa3.eoxvantage.com/images/wallpaper.png"
				  }
				}
			  },
			  "login": {
				"username": null,
				"password": null
			  }
			},
			"settings": {
			  "lock": [],
			  "defaults": {
				"osjs/session": [],
				"osjs/desktop": {},
				"osjs/locale": {}
			  }
			},
			"search": {
			  "enabled": false
			},
			"notifications": {
			  "native": false
			},
			"desktop": {
			  "lock": false,
			  "contextmenu": true,
			  "settings": {
				"font": "Lato",
				"theme": "Vision",
				"sounds": "FreedesktopSounds",
				"icons": "oxzioniconpack",
				"animations": false,
				"widgets": [],
				"keybindings": [],
				"background": {
				  "color": "transparent",
				  "style": "cover"
				},
				"iconview": {
				  "enabled": false
				},
				"panels": [
				  {
					"position": "top",
					"items": [
					  {
						"name": "menu"
					  },
					  {
						"name": "windows"
					  },
					  {
						"name": "notificationCenter"
					  },
					  {
						"name": "tray"
					  },
					  {
						"name": "profile"
					  },
					  {
						"name": "logout"
					  }
					]
				  }
				]
			  }
			},
			"locale": {
			  "language": "en_GB",
			  "rtl": [
				"az",
				"fa",
				"he",
				"uz",
				"ar"
			  ],
			  "format": {
				"shortDate": "yyyy-mm-dd",
				"mediumDate": "dS mmm yyyy",
				"longDate": "dS mmmm yyyy",
				"fullDate": "dddd dS mmmm yyyy",
				"shortTime": "HH:MM",
				"longTime": "HH:MM:ss"
			  }
			},
			"windows": {
			  "lofi": false,
			  "mobile": false,
			  "template": null
			},
			"vfs": {
			  "defaultPath": "/vfs",
			  "defaultAdapter": "system",
			  "adapters": {},
			  "mountpoints": [
				{
				  "name": "apps",
				  "label": "Applications",
				  "adapter": "apps",
				  "icon": {
					"default": "https://qa3.eoxvantage.com/images/logo-blue-32x32.png"
				  },
				  "attributes": {
					"visibility": "restricted",
					"readOnly": true
				  }
				},
				{
				  "name": "bos",
				  "label": "bOS",
				  "adapter": "system",
				  "icon": {
					"name": "folder-publicshare"
				  }
				},
				{
				  "name": "home",
				  "label": "Home",
				  "adapter": "system",
				  "icon": {
					"name": "user-home"
				  }
				}
			  ],
			  "icons": {
				"^application/zip": {
				  "name": "package-x-generic"
				},
				"^application/javascript": {
				  "name": "text-x-script"
				},
				"^application/json": {
				  "name": "text-x-script"
				},
				"^application/x-python": {
				  "name": "text-x-script"
				},
				"^application/php": {
				  "name": "text-x-script"
				},
				"^application/pdf": {
				  "name": "x-office-document"
				},
				"^application/rtf": {
				  "name": "x-office-document"
				},
				"^application/msword": {
				  "name": "x-office-document"
				},
				"^application/(xz|tar|gzip)": {
				  "name": "package-x-generic"
				},
				"^text/css": {
				  "name": "text-x-script"
				},
				"^text/html": {
				  "name": "text-html"
				},
				"^(application|text)/xml": {
				  "name": "text-html"
				},
				"^application": {
				  "name": "application-x-executable"
				},
				"^text": {
				  "name": "text-x-generic"
				},
				"^audio": {
				  "name": "audio-x-generic"
				},
				"^video": {
				  "name": "video-x-generic"
				},
				"^image": {
				  "name": "image-x-generic"
				}
			  }
			},
			"providers": {
			  "globalBlacklist": [
				"osjs/websocket",
				"osjs/clipboard",
				"osjs/gapi"
			  ],
			  "globalWhitelist": []
			},
			"wrapper": {
			  "url": "https://qa3.eoxvantage.com:9080/"
			},
			"ui": {
			  "url": "https://qa3.eoxvantage.com/"
			},
			"api": {
			  "url": "https://qa3.eoxvantage.com:9080/"
			}
		  },
		  "options": {
			"classNames": [
			  "osjs-root"
			],
			"root": {
			  "_reactListening84x9o9tfo4": true,
			  "__reactEvents$3vwq7jg674l": {}
			}
		  },
		  "booted": true,
		  "started": true,
		  "destroyed": false,
		  "providers": {},
		  "user": {
			"jwt": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2NzU2Njk2ODMsImp0aSI6Ijg1YUhPU1pWU1QwQVpPbDJlVFIzWHFoenlmU0NQRkNNOXBKbWxTM1BWbkU9IiwibmJmIjoxNjc1NjY5NjgzLCJleHAiOjE2NzU3NDE2ODMsImRhdGEiOnsidXNlcm5hbWUiOiJ5YXNod2FudGhuIiwiYWNjb3VudElkIjozfX0.khojsfFvU_eiiDOqwQl-l4ijHJIQfonggcJ7I7WFLe2MK0LPesYR_98HEv8cv1KrWQWajrfEobvudy3Mf4edsQ",
			"username": "yashwanthn"
		  },
		  "ws": {
			"name": "Websocket@CoreSocket",
			"events": {
			  "open": [
				{
				  "options": {}
				}
			  ],
			  "close": [
				{
				  "options": {}
				}
			  ],
			  "connected": [
				{
				  "options": {}
				}
			  ],
			  "failed": [
				{
				  "options": {
					"once": true
				  }
				}
			  ],
			  "disconnected": [
				{
				  "options": {}
				}
			  ],
			  "message": [
				{
				  "options": {}
				}
			  ]
			},
			"uri": "wss://qa3.eoxvantage.com/",
			"connected": true,
			"connecting": false,
			"connectfailed": false,
			"options": {
			  "interval": 5000,
			  "open": true
			},
			"connection": {}
		  },
		  "ping": 2247,
		  "$root": {
			"_reactListening84x9o9tfo4": true,
			"__reactEvents$3vwq7jg674l": {}
		  },
		  "$resourceRoot": {},
		  "requestOptions": {}
		},
		"uiUrl": "https://qa3.eoxvantage.com/",
		"wrapperUrl": "https://qa3.eoxvantage.com:9080/",
		"placeholder": "",
		"prefix": "",
		"customClass": "",
		"suffix": "",
		"multiple": false,
		"protected": false,
		"unique": false,
		"persistent": true,
		"hidden": false,
		"clearOnHide": true,
		"refreshOn": "",
		"redrawOn": "",
		"modalEdit": false,
		"labelPosition": "top",
		"description": "",
		"errorLabel": "",
		"hideLabel": false,
		"disabled": false,
		"autofocus": false,
		"dbIndex": false,
		"customDefaultValue": "",
		"calculateValue": "",
		"calculateServer": false,
		"attributes": {},
		"validateOn": "change",
		"conditional": {
		  "show": null,
		  "when": null,
		  "eq": ""
		},
		"overlay": {
		  "style": "",
		  "left": "",
		  "top": "",
		  "width": "",
		  "height": ""
		},
		"allowCalculateOverride": false,
		"encrypted": false,
		"showCharCount": false,
		"showWordCount": false,
		"properties": {},
		"allowMultipleMasks": false,
		"idPath": "id",
		"clearOnRefresh": false,
		"limit": 100,
		"dataSrc": "values",
		"valueProperty": "",
		"lazyLoad": true,
		"filter": "",
		"searchEnabled": true,
		"searchField": "",
		"minSearch": 0,
		"readOnlyValue": false,
		"authenticate": false,
		"template": "<span>{{ item.label }}</span>",
		"selectFields": "",
		"searchThreshold": 0.3,
		"uniqueOptions": false,
		"fuseOptions": {
		  "include": "score",
		  "threshold": 0.3
		},
		"customOptions": {},
		"id": "ecl26d"
	  }
];
export default { components };
